import axios from '@/common/axios'
import qs from 'qs'

export function addSettlingBacteriaRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/settlingBacteriaRecord/add',
    data: qs.stringify(data)
  })
}

export function deleteSettlingBacteriaRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/settlingBacteriaRecord/delete/' + id
  })
}

export function updateSettlingBacteriaRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/settlingBacteriaRecord/update',
    data: qs.stringify(data)
  })
}

export function selectSettlingBacteriaRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/settlingBacteriaRecord/info/' + id
  })
}

export function selectSettlingBacteriaRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/settlingBacteriaRecord/list',
    params: query
  })
}

export function reviewSettlingBacteriaRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/settlingBacteriaRecord/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalSettlingBacteriaRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/settlingBacteriaRecord/updateApprove',
    data: qs.stringify(data)
  })
}
