<template>
  <div id="settlingBacteriaRecord">
    <el-dialog
      :title="settlingBacteriaRecordFormTitle"
      width="1200px"
      :visible.sync="settlingBacteriaRecordDialogVisible"
      :close-on-click-modal="false"
      @close="settlingBacteriaRecordDialogClose"
    >
      <div id="pdfDom">
      <el-form
        ref="settlingBacteriaRecordFormRef"
        :model="settlingBacteriaRecordForm"
        :rules="settlingBacteriaRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="培养温度" prop="temperature">
              <el-input
                v-model="settlingBacteriaRecordForm.temperature"
                placeholder="请输入培养温度"
                clearable
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养时间" prop="cultureTime">
              <el-date-picker
                v-model="settlingBacteriaRecordForm.cultureTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养基名称" prop="mediumName">
              <el-input
                v-model="settlingBacteriaRecordForm.mediumName"
                placeholder="请输入培养基名称"
                clearable
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养基批号" prop="batchNo">
              <el-input
                v-model="settlingBacteriaRecordForm.batchNo"
                placeholder="请输入培养基批号"
                clearable
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="settlingBacteriaRecordForm.deviceName"
                placeholder="请输入设备名称"
                clearable
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input
                v-model="settlingBacteriaRecordForm.deviceNo"
                placeholder="请输入设备编号"
                clearable
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测时间" prop="detectionTime">
              <el-date-picker
                v-model="settlingBacteriaRecordForm.detectionTime"
                placeholder="请选择检测时间"
                value-format="yyyy-MM-dd"
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="settlingBacteriaRecordForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="bacteriaRecordDetailList"
            :merge-cells="[{ row: 0, col: 0, rowspan: 21, colspan: 1 }]"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="ratingLevel"
              title="评定级别"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="area"
              title="所属区域"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="samplingPoints"
              title="采样点数"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="colonies"
              title="菌落数记录cfu"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="averageColonies"
              title="平均菌落数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="结果判定"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options:[
                  { value: 1, label: '合格' },
                  { value: 2, label: '不合格' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="结论" prop="conclusion">
              <el-radio-group
                v-model="settlingBacteriaRecordForm.conclusion"
                :disabled="settlingBacteriaRecordFormTitle !== '新增沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '修改沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="2">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="settlingBacteriaRecordForm.reviewResult"
                :disabled="settlingBacteriaRecordFormTitle !== '复核沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="settlingBacteriaRecordForm.approvalResult"
                :disabled="settlingBacteriaRecordFormTitle !== '批准沉降菌检测记录'
                  && settlingBacteriaRecordFormTitle !== '沉降菌检测记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      </div>
      <div slot="footer">
        <el-button @click="settlingBacteriaRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="settlingBacteriaRecordFormSubmit">
          确 定
        </el-button>
        <el-button v-if="settlingBacteriaRecordFormTitle === '沉降菌检测记录详情'" type="primary" @click="getPdf">
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检测时间">
        <el-date-picker v-model="searchForm.detectionTime" placeholder="请选择检测时间" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SETTLING_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="settlingBacteriaRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="temperature" label="培养温度" />
      <el-table-column prop="cultureTime" label="培养时间" />
      <el-table-column prop="mediumName" label="培养基名称" />
      <el-table-column prop="batchNo" label="培养基批号" />
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column label="检测时间">
        <template slot-scope="scope">
          <span v-if="scope.row.detectionTime">{{ scope.row.detectionTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="conclusion" label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion == 1">合格</span>
          <span v-if="scope.row.conclusion == 2">不合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SETTLING_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SETTLING_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['SETTLING_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <!-- <el-button
            v-if="checkPermission(['SETTLING_APPROVAL'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="settlingBacteriaRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSettlingBacteriaRecord,
  deleteSettlingBacteriaRecord,
  updateSettlingBacteriaRecord,
  selectSettlingBacteriaRecordInfo,
  selectSettlingBacteriaRecordList,
  reviewSettlingBacteriaRecord,
  approvalSettlingBacteriaRecord
} from '@/api/quality/settlingBacteriaRecord'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      settlingBacteriaRecordDialogVisible: false,
      settlingBacteriaRecordFormTitle: '',
      settlingBacteriaRecordForm: {
        id: '',
        temperature: '',
        cultureTime: '',
        mediumName: '',
        batchNo: '',
        deviceName: '',
        deviceNo: '',
        detectionTime: '',
        reportDate: '',
        conclusion: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        bacteriaRecordDetailJson: '',
        taskId: ''
      },
      settlingBacteriaRecordFormRules: {
        detectionTime: [{ required: true, message: '检测时间不能为空', trigger: ['blur', 'change']}]
      },
      settlingBacteriaRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        detectionTime: ''
      },
      bacteriaRecordDetailList: [
        // { ratingLevel: '100，000级', area: '女一更', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '男一更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '女二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '男二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '洗衣间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '器具清洗间', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '脱包间', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '初洗间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '清洗间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '暂存间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '组装车间', samplingPoints: '3', result: '' },
        { ratingLevel: '100，000级', area: '焊接间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '无菌室缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '微生物限度室缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '无菌室', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '微生物限度室', samplingPoints: '2', result: '' }
      ]
    }
  },
  created () {
    selectSettlingBacteriaRecordList(this.searchForm).then(res => {
      this.settlingBacteriaRecordPage = res
    })
  },
  methods: {
    settlingBacteriaRecordDialogClose () {
      this.$refs.settlingBacteriaRecordFormRef.resetFields()
      this.bacteriaRecordDetailList = [
        // { ratingLevel: '100，000级', area: '女一更', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '男一更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '女二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '男二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '洗衣间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '器具清洗间', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '脱包间', samplingPoints: '2', result: '' },
        // { ratingLevel: '100，000级', area: '初洗间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '清洗间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '暂存间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '组装车间', samplingPoints: '3', result: '' },
        { ratingLevel: '100，000级', area: '焊接间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '阳性间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '二更', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '无菌室缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '微生物限度室缓冲间', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '无菌室', samplingPoints: '2', result: '' },
        { ratingLevel: '100，000级', area: '微生物限度室', samplingPoints: '2', result: '' }
      ]
    },
    settlingBacteriaRecordFormSubmit () {
      if (this.settlingBacteriaRecordFormTitle === '沉降菌检测记录详情') {
        this.settlingBacteriaRecordDialogVisible = false
        return
      }
      this.$refs.settlingBacteriaRecordFormRef.validate(async valid => {
        if (valid) {
          this.settlingBacteriaRecordForm.cultureTime = this.settlingBacteriaRecordForm.cultureTime.join('至')
          if (this.settlingBacteriaRecordFormTitle === '新增沉降菌检测记录') {
            this.settlingBacteriaRecordForm.id = ''
            this.settlingBacteriaRecordForm.status = 1
            this.settlingBacteriaRecordForm.bacteriaRecordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addSettlingBacteriaRecord(this.settlingBacteriaRecordForm)
          } else if (this.settlingBacteriaRecordFormTitle === '修改沉降菌检测记录') {
            this.settlingBacteriaRecordForm.bacteriaRecordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateSettlingBacteriaRecord(this.settlingBacteriaRecordForm)
          } else if (this.settlingBacteriaRecordFormTitle === '复核沉降菌检测记录') {
            this.settlingBacteriaRecordForm.status = 2
            await reviewSettlingBacteriaRecord(this.settlingBacteriaRecordForm)
          } else if (this.settlingBacteriaRecordFormTitle === '批准沉降菌检测记录') {
            this.settlingBacteriaRecordForm.status = 3
            await approvalSettlingBacteriaRecord(this.settlingBacteriaRecordForm)
          }
          this.settlingBacteriaRecordPage = await selectSettlingBacteriaRecordList(this.searchForm)
          this.settlingBacteriaRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.settlingBacteriaRecordFormTitle = '新增沉降菌检测记录'
      this.settlingBacteriaRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSettlingBacteriaRecord(row.id)
        if (this.settlingBacteriaRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.settlingBacteriaRecordPage = await selectSettlingBacteriaRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.settlingBacteriaRecordFormTitle = '修改沉降菌检测记录'
      this.settlingBacteriaRecordDialogVisible = true
      this.selectSettlingBacteriaRecordInfoById(row.id)
    },
    handleReview (index, row) {
      this.settlingBacteriaRecordFormTitle = '复核沉降菌检测记录'
      this.settlingBacteriaRecordDialogVisible = true
      this.selectSettlingBacteriaRecordInfoById(row.id)
    },
    handleApproval (index, row) {
      this.settlingBacteriaRecordFormTitle = '批准沉降菌检测记录'
      this.settlingBacteriaRecordDialogVisible = true
      this.selectSettlingBacteriaRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.settlingBacteriaRecordFormTitle = '沉降菌检测记录详情'
      this.settlingBacteriaRecordDialogVisible = true
      this.selectSettlingBacteriaRecordInfoById(row.id)
    },
    selectSettlingBacteriaRecordInfoById (id) {
      selectSettlingBacteriaRecordInfo(id).then(res => {
        this.settlingBacteriaRecordForm.id = res.id
        this.settlingBacteriaRecordForm.temperature = res.temperature
        this.settlingBacteriaRecordForm.cultureTime = res.cultureTime.split('至')
        this.settlingBacteriaRecordForm.mediumName = res.mediumName
        this.settlingBacteriaRecordForm.batchNo = res.batchNo
        this.settlingBacteriaRecordForm.deviceName = res.deviceName
        this.settlingBacteriaRecordForm.deviceNo = res.deviceNo
        this.settlingBacteriaRecordForm.detectionTime = res.detectionTime
        this.settlingBacteriaRecordForm.reportDate = res.reportDate
        this.settlingBacteriaRecordForm.conclusion = res.conclusion
        this.settlingBacteriaRecordForm.reviewResult = res.reviewResult
        this.settlingBacteriaRecordForm.approvalResult = res.approvalResult
        this.settlingBacteriaRecordForm.status = res.status
        this.settlingBacteriaRecordForm.taskId = res.taskId
        this.bacteriaRecordDetailList = res.bacteriaRecordDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSettlingBacteriaRecordList(this.searchForm).then(res => {
        this.settlingBacteriaRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSettlingBacteriaRecordList(this.searchForm).then(res => {
        this.settlingBacteriaRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSettlingBacteriaRecordList(this.searchForm).then(res => {
        this.settlingBacteriaRecordPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['SETTLING_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `沉降菌检测记录${this.settlingBacteriaRecordForm.detectionTime.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
